import * as React from 'react'
import { GlobalErrorModal } from '@components/modals/global-error-modal'
import { ModalProps } from 'react-bootstrap'
import { RodoAgreementQrCodeModal } from '@modules/rodo-agreements/modals/qr-code-modal'
import { RodoAgreementContentModal } from '@modules/rodo-agreements/modals/agreement-modal'
import { RodoAgreementDataExistModal } from '@modules/rodo-agreements/modals/agreement-data-exist-modal'
import { ClientAppDetailsModal } from '@components/modals/client-app-details-modal'
import { ConfirmationModal } from '@components/modals/confirmation-modal'
import { EndlessHolidayDescriptionModal } from '@modules/endless-holiday/details/description-modal'
import { AccommodationTypeDetailsModal } from '@modules/holiday-benefit/common/accommodation-type-details-modal'

export type ModalTypes =
  | 'GlobalErrorModal'
  | 'ConfirmationModal'
  | 'RodoAgreementQrCodeModal'
  | 'RodoAgreementContentModal'
  | 'RodoAgreementDataExistModal'
  | 'ClientAppDetailsModal'
  | 'EndlessHolidayDescriptionModal'
  | 'AccommodationTypeDetailsModal'

export type ModalParams = ModalProps

type ModalsMapType = {
  [key in ModalTypes]: [React.FC<ModalProps & BaseModalProps>, ModalParams]
}

export const ModalsMap: ModalsMapType = {
  GlobalErrorModal: [GlobalErrorModal, { centered: true }],
  ConfirmationModal: [ConfirmationModal, { centered: true }],
  RodoAgreementQrCodeModal: [RodoAgreementQrCodeModal, { centered: true, className: 'rodo-agreements__qr-modal' }],
  RodoAgreementContentModal: [RodoAgreementContentModal, { centered: true, className: 'rodo-agreements__qr-modal' }],
  EndlessHolidayDescriptionModal: [EndlessHolidayDescriptionModal, { centered: true, size: 'lg' }],
  AccommodationTypeDetailsModal: [AccommodationTypeDetailsModal, { centered: true, size: 'lg' }],
  RodoAgreementDataExistModal: [
    RodoAgreementDataExistModal,
    { centered: true, className: 'rodo-agreements__qr-modal' },
  ],

  ClientAppDetailsModal: [ClientAppDetailsModal, { centered: true, size: 'sm' }],
}

export interface ModalConfig {
  persist?: boolean // nie usuwa modala po wyjściu z widoku
  onClose?: () => void
}

export interface BaseModalProps {
  toggleIsVisible: () => void
}
