import { asDecimal } from '@helpers/utils'
import { useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayFormInputs } from '@modules/endless-holiday/details'
import { EndlessHolidayProduct } from '@modules/endless-holiday/models'

interface Response {
  price: string
}

export const useEndlessHolidayProductPrice = (
  showDiscountedPrice: boolean,
  subscriptionProduct: EndlessHolidayProduct,
  priceSurplusWithoutGastro: string,
): Response => {
  const { control } = useFormContext<EndlessHolidayFormInputs>()
  const products = useWatch({ control, name: 'products' })

  const isAdded = products.some(product => product.product.id === subscriptionProduct.id)
  const hasGastroCard = products.some(product => product.related_product === subscriptionProduct.id)

  const priceToShow = showDiscountedPrice ? subscriptionProduct.first_price_brutto : subscriptionProduct.price_brutto

  const price =
    isAdded && !hasGastroCard ? asDecimal(priceToShow).plus(priceSurplusWithoutGastro).toString() : priceToShow

  return { price }
}
