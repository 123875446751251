import * as React from 'react'
import { EndlessHolidayProduct } from '@modules/endless-holiday/models'
import { useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayFormInputProduct, EndlessHolidayFormInputs } from '@modules/endless-holiday/details'
import { ProductVariantOptionBox } from '@modules/endless-holiday/details/products-selection/product-variant/options/product-variant-option-box'
import { formatPriceShort } from '@helpers/price-helper'
import { asDecimal } from '@helpers/utils'

interface GroupedProducts {
  currentGastroProduct: EndlessHolidayFormInputProduct | null
  restProducts: EndlessHolidayFormInputProduct[]
}

interface Props {
  gastroProducts: EndlessHolidayProduct[]
  subscriptionProduct: EndlessHolidayProduct
  priceSurplusWithoutGastro: string
}

export const ProductVariantGastroCards = ({
  gastroProducts,
  subscriptionProduct,
  priceSurplusWithoutGastro,
}: Props): React.ReactNode => {
  const { setValue, control } = useFormContext<EndlessHolidayFormInputs>()
  const products = useWatch({ control, name: 'products' })

  const getSubscriptionProductWithUpdatedPrice = (
    product: EndlessHolidayFormInputProduct,
    firstPrice: string,
    defaultPrice: string,
  ) => ({
    ...product,
    product: {
      ...product.product,
      first_price_brutto: firstPrice,
      price_brutto: defaultPrice,
    },
  })

  const handleClick = (gastroProduct: EndlessHolidayProduct) => () => {
    const { currentGastroProduct, restProducts } = products.reduce(
      (acc, product) => {
        if (product.related_product === subscriptionProduct.id) {
          return { ...acc, currentGastroProduct: product }
        }

        if (product.product.id === subscriptionProduct.id) {
          return {
            ...acc,
            restProducts: [
              ...acc.restProducts,
              getSubscriptionProductWithUpdatedPrice(
                product,
                subscriptionProduct.first_price_brutto,
                subscriptionProduct.price_brutto,
              ),
            ],
          }
        }

        return { ...acc, restProducts: [...acc.restProducts, product] }
      },
      { restProducts: [], currentGastroProduct: null },
    ) as GroupedProducts

    setValue('products', [
      ...restProducts,
      {
        product: gastroProduct,
        amount: currentGastroProduct?.amount ?? 1,
        related_product: subscriptionProduct.id,
        options: [],
      },
    ])
  }

  const isChecked = (gastroProduct: EndlessHolidayProduct) =>
    products.some(
      product => product.product.id === gastroProduct.id && product.related_product === subscriptionProduct.id,
    )

  const handleNoGastroVoucherClick = () => {
    const getUpdatedPrice = (price: string) => asDecimal(price).plus(priceSurplusWithoutGastro).toString()

    setValue(
      'products',
      products.reduce((prev, product) => {
        if (product.related_product === subscriptionProduct.id) {
          return prev
        }

        if (product.product.id === subscriptionProduct.id) {
          return [
            ...prev,
            getSubscriptionProductWithUpdatedPrice(
              product,
              getUpdatedPrice(subscriptionProduct.first_price_brutto),
              getUpdatedPrice(subscriptionProduct.price_brutto),
            ),
          ]
        }

        return [...prev, product]
      }, []),
    )
  }

  const hasGastroCard = products.some(product => product.related_product === subscriptionProduct.id)
  const isAdded = products.some(product => product.product.id === subscriptionProduct.id)

  return (
    <div>
      <p className="font-500 font-lg mb-1 text-center text-lg-start">Wybierz wariant vouchera gastronomicznego:</p>
      <div className="row gap-3 gap-lg-0">
        <ProductVariantOptionBox
          title="Bez vouchera"
          isChecked={isAdded && !hasGastroCard}
          onClick={handleNoGastroVoucherClick}
          text={
            <span>
              Dokonując zakupu bez vouchera gastronomicznego cena pobytu wzrasta o{' '}
              <span className="font-500 text-nowrap">{formatPriceShort(priceSurplusWithoutGastro)}</span>
            </span>
          }
        />
        {gastroProducts.map((product, index) => (
          <ProductVariantOptionBox
            isChecked={isChecked(product)}
            key={product.id}
            title={formatPriceShort(product.price_brutto)}
            onClick={handleClick(product)}
            text={
              index === gastroProducts.length - 1 ? (
                <div className="lh-sm">
                  Otrzymasz voucher gastronomiczny <span className="text-nowrap">o wartości</span>{' '}
                  <span className="font-500 text-nowrap">{formatPriceShort(product.price_brutto)}</span> do
                  wykorzystania przez 3 lata <span className="text-nowrap">we wszystkich</span> punktach
                  gastronomicznych łącznie <span className="text-nowrap">z restauracją </span>oraz dostęp do lokali
                  specjalnych.
                </div>
              ) : (
                <div className="lh-sm">
                  Otrzymasz voucher gastronomiczny <span className="text-nowrap">o wartości</span>{' '}
                  <span className="font-500 text-nowrap"> {formatPriceShort(product.price_brutto)}</span> do
                  wykorzystania przez 3 lata we wszystkich punktach gastronomicznych łącznie{' '}
                  <span className="text-nowrap">z restauracją.</span>
                </div>
              )
            }
          />
        ))}
      </div>
    </div>
  )
}
