import * as React from 'react'
import { EndlessHolidayConfirmationDetails } from '@modules/endless-holiday/models'
import { useGroupedEndlessSubscriptionProducts } from '@modules/endless-holiday/confirmation/use-grouped-endless-subscription-products'
import clsx from 'clsx'
import { EndlessHolidayConfirmationSubscriptionProductRow } from '@modules/endless-holiday/confirmation/products/confirmation-subscription-product-row'
import { EndlessHolidayConfirmationGastroProducts } from '@modules/endless-holiday/confirmation/products/confirmation-gastro-products'

interface Props {
  productDetails: EndlessHolidayConfirmationDetails
}

export const EndlessHolidayConfirmationProducts = ({ productDetails }: Props): React.ReactNode => {
  const { groupedList } = useGroupedEndlessSubscriptionProducts(productDetails)

  const hasBrownOrSilver = productDetails.subscriptions.some(subscription =>
    ['brown', 'silver'].includes(subscription.kind),
  )

  return (
    <div>
      <h5 className="text-secondary">Wybrane produkty:</h5>
      <section className="mt-3">
        {groupedList.map(([year, subscriptions], index) => (
          <div key={year} className="position-relative">
            <div className="d-flex align-items-center">
              {groupedList[index + 1] && <div className="endless-holiday__confirmation__product-timeline__line" />}
              <div className="endless-holiday__confirmation__product-timeline__point">
                <i className="uil-check text-darker-gray" />
              </div>
              <h6 className="text-secondary my-3 my-sm-0 ms-3 font-lg">Od roku {year}</h6>
            </div>
            <ul className="mb-2">
              {subscriptions.map((subscription, index) => (
                <EndlessHolidayConfirmationSubscriptionProductRow
                  className={clsx({ 'mt-2': index > 0 })}
                  key={subscription.id}
                  subscription={subscription}
                />
              ))}
            </ul>
            <EndlessHolidayConfirmationGastroProducts gastroCards={productDetails.gastro_cards} year={year} />
          </div>
        ))}
      </section>
      {hasBrownOrSilver && <small className="text-muted">*Z wyłączeniem terminów specjalnych.</small>}
    </div>
  )
}
