import { getYear, subYears } from 'date-fns'
import { parseISODate } from '@helpers/date-formatter'
import {
  EndlessHolidayConfirmationDetails,
  EndlessHolidayConfirmationSubscription,
} from '@modules/endless-holiday/models'

// 5 years but its end at the end of 4th year
const ENDLESS_HOLIDAY_VOUCHER_ACTIVE_YEARS = 4

interface Response {
  groupedList: [string, EndlessHolidayConfirmationSubscription[]][]
}

export const useGroupedEndlessSubscriptionProducts = (productDetails: EndlessHolidayConfirmationDetails): Response => {
  const groupedByYears = productDetails.subscriptions.reduce((acc, subscription) => {
    const subscriptionYear = getYear(
      subYears(parseISODate(subscription.expire_after), ENDLESS_HOLIDAY_VOUCHER_ACTIVE_YEARS),
    )

    if (acc[subscriptionYear]) {
      return { ...acc, [subscriptionYear]: [...acc[subscriptionYear], subscription] }
    }
    return { ...acc, [subscriptionYear]: [subscription] }
  }, {})

  const groupedList = Object.entries(groupedByYears) as [string, EndlessHolidayConfirmationSubscription[]][]

  return {
    groupedList,
  }
}
