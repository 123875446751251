import * as React from 'react'
import clsx from 'clsx'
import { EndlessHolidayConfirmationSubscription } from '@modules/endless-holiday/models'
import { formatPrice } from '@helpers/price-helper'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { asDecimal, sumDecimalArray } from '@helpers/utils'
import { EndlessHolidayConfirmationProductBaseRow } from '@modules/endless-holiday/confirmation/products/confirmation-product-base-row'

interface Props {
  subscription: EndlessHolidayConfirmationSubscription
  className: string
}

export const EndlessHolidayConfirmationSubscriptionProductRow = ({
  subscription,
  className,
}: Props): React.ReactNode => {
  const name = {
    gold: 'Pakiet złoty',
    silver: 'Pakiet srebrny',
    brown: 'Pakiet brązowy',
  }[subscription.kind]

  const usageDates = {
    gold: 'od 01.01 do 07.07 oraz od 26.08 do 31.12',
    silver: 'od 01.01 do 30.06 oraz od 01.09 do 31.12*',
    brown: '01.01 do 31.05 oraz od 01.10 do 31.12*',
  }[subscription.kind]

  const accommodationType = ACCOMMODATION_TYPES.HOUSES.includes(subscription.accommodation_type)
    ? require('@assets/images/house.webp')
    : require('@assets/images/apartment.webp')

  const totalDepositPrice = asDecimal(subscription.deposit_amount).mul(subscription.number_of_codes).toString()
  const stayDateOptionPrice = asDecimal(
    sumDecimalArray(subscription.options.map(option => asDecimal(option.price_brutto))),
  )
    .mul(subscription.number_of_codes)
    .toString()

  const totalPrice = asDecimal(subscription.price_brutto).add(totalDepositPrice).add(stayDateOptionPrice).toString()

  return (
    <EndlessHolidayConfirmationProductBaseRow
      className={className}
      kind={subscription.kind}
      title={name}
      image={accommodationType}
      subtitle={
        <span className="text-muted font-sm lh-base">
          Wykorzystaj w terminie: <br />
          {usageDates}
        </span>
      }
    >
      <div>
        <Row title="Koszt pakietu" price={subscription.price_brutto} />
        <Row title="Kaucja zwrotna do przyszłej rezerwacji" price={totalDepositPrice} className="my-2 my-sm-0" />
        {!!subscription.options.length && <Row title="Jednorazowa zmiana daty" price={stayDateOptionPrice} />}
      </div>
      <Row title="Łączny koszt" price={totalPrice} className="fw-bold text-darker-gray mt-2 font-lg" />
    </EndlessHolidayConfirmationProductBaseRow>
  )
}

const Row = ({ title, price, className }: { title: string; price: string; className?: string }) => (
  <div className={clsx('d-flex flex-wrap font-sm text-muted', className)}>
    <div className="col-sm-8 col-12 text-sm-end text-center">{title}:</div>
    <div className="col-sm-4 col-12 text-sm-end text-center">{formatPrice(price)}</div>
  </div>
)
