import * as React from 'react'
import { ProductsVariantsYear } from '@modules/endless-holiday/details/products-selection/product-variant/product-variants-year'
import { EndlessHolidayDetails, EndlessHolidayProduct } from '@modules/endless-holiday/models'
import { useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayFormInputs } from '@modules/endless-holiday/details'

interface GroupedProducts {
  [year: string]: EndlessHolidayProduct[]
}

interface Props {
  productDetails: EndlessHolidayDetails
}

export const ProductsVariantsYears = ({ productDetails }: Props): React.ReactNode => {
  const { control } = useFormContext<EndlessHolidayFormInputs>()
  const accommodation = useWatch({ control, name: 'accommodation' })

  const groupedSubscriptionByYears: GroupedProducts = productDetails.product_package_set.products.reduce(
    (acc, product) => {
      if (!product.stay_in_years || !product.stay_in_years.length || product.kind !== 'subscription') return acc

      const productYear = product.stay_in_years[0].toString()

      const appendProduct = (previous = []) => ({ ...acc, [productYear]: [...previous, product] })
      const isYearAlreadyInAcc = Object.keys(acc).includes(productYear)
      return isYearAlreadyInAcc ? appendProduct(acc[productYear]) : appendProduct()
    },
    {},
  )

  const gastroProducts = productDetails.product_package_set.products.filter(
    product => product.kind === 'gastro_card_product_package_endless_holiday',
  )

  const elementsToShow = Object.entries(groupedSubscriptionByYears).filter(([, products]) => {
    const selectedAccommodation = accommodation === 'house' ? 1 : 2
    return !!products.length && products.some(product => product.accommodation_type === selectedAccommodation)
  })

  return (
    <div>
      {elementsToShow.map(([year, products]) => (
        <ProductsVariantsYear
          key={year}
          year={year}
          products={products}
          gastroProducts={gastroProducts}
          showDiscountedPrice={productDetails.can_use_first_price_brutto}
          priceSurplusWithoutGastro={productDetails.product_package_set.no_gastro_card_additional_fee}
        />
      ))}
    </div>
  )
}
