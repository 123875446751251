import { GastroCardDetails } from '@modules/gastro-card/models'
import React from 'react'
import { PaymentProvider } from '@models/app-data'

type GastroCouponPaymentMethods = 'online' | 'cash_card'
export type GastroGenericProductTag = 'subscription_offer' | 'no_subscription_offer'

export interface GastroGenericProductDetails extends GastroCardDetails {
  payment_method: GastroCouponPaymentMethods
  has_rules_to_accept: boolean
  kind: GastroProductKind
  tags: GastroGenericProductTag[]
}

export type GastroProductAssignType = 'booking' | 'client'

export const AvailableGastroProductKind = [
  'gastro_coupon',
  'gastro_card_boost',
  'normal',
  'gastro_card_2024',
  'gastro_card_2025',
  'gastro_card_2026',
  'gastro_card_2026_october',
  'gastro_card_2026_november',
  'gastro_card_2027',
  'gastro_card_2024_wholesale',
  'gastro_card_2025_wholesale',
  'gastro_card_dynamic',
] as const

export type GastroProductKind = (typeof AvailableGastroProductKind)[number]

export type GastroProducts = {
  [key in GastroProductKind]: GastroProduct
}

export interface GastroProductMultiplier {
  active_from: string
  active_to: string
  minimal_amount: string
  multiplier_value: string
  multiplier_value_for_subscriber: string
}

export interface GastroProduct {
  minimal_value: string
  product_name: string
  assign_type: GastroProductAssignType
  kind: GastroProductKind
  multiplier: number
  multipliers: GastroProductMultiplier[]
  payment_providers: PaymentProvider[]
  date_from: string
  date_to: string
}

export interface GastroProductData {
  labelImageSrc: string
  productName: string
  productHeader: React.ReactNode
  alertText: string
  benefitsPoints: string[]
  benefitsList: string[]
}
